<template>
  <v-container fluid class="pa-6">
    <v-row v-if="types.length">
      <v-col xs="12" sm="12" md="6" lg="4">
        <ProvidersComponent v-for="(type, index) in getTypes(1)" :key="index" :type="type" class="mb-4" />
      </v-col>
      <v-col xs="12" sm="12" md="6" lg="4">
        <ProvidersComponent v-for="(type, index) in getTypes(2)" :key="index" :type="type" class="mb-4" />
      </v-col>
      <v-col xs="12" sm="12" md="6" lg="4">
        <ProvidersComponent v-for="(type, index) in getTypes(3)" :key="index" :type="type" class="mb-4" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProvidersComponent from "@/modules/administration/administrationComponents/providersComponent";
import GenericDataService from '@/services/GenericDataService';

export default {
  name: "AdministrationProviders",
  components: { ProvidersComponent },
  data() {
    return {
      types: []
    };
  },
  mounted() {
    GenericDataService.getData('/hard_value/getValuesByType?type=provider_types').then((response) => {
      let tmpTypes = response.data.data;

      for(const type in tmpTypes) {
        tmpTypes[type].type = type;
        this.types.push(tmpTypes[type]);
      }
    });
  },
  methods: {
    getTypes(col) {
      let tmp = Math.ceil((this.types.length / 3));
      let tmp2 = tmp * (col - 1);

      if(col > 1) {
        let i = (this.types.length - tmp) / 2;
        if(i % 2 == 0 || (i === 1 && this.types.length > 3)) {
          tmp2 = tmp + (i * (col - 2));
          tmp = i;
        }
      }

      return this.types.slice(tmp2, tmp + tmp2);
    }
  }
};
</script>

<style lang="scss">
</style>
